import React from 'react'
import './row.css'
import GameCard from './GameCard.js'

function Row ({games, rowname, subtitle}) {
    return (
        <div className='row-wrapper'>
            <h1 className='row-header'>{rowname}</h1>
            {subtitle && <h2 className='row-subtitle'>{subtitle}</h2>}
            <div className='row'>
                {games.map((game) => (
                    <GameCard gamename={game.gamename}
                              store={game.store}
                              gamedescription={game.gamedescription}
                              gamelink={game.gamelink}
                              gamecoverurl={game.gamecoverurl}
                              freeuntil={game.freeuntil}
                              key={game.gamename} />
                )
                )}
            </div>
        </div>
    )
}

export default Row
