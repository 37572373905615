import React from 'react'
import './gamecard.css'

function GameCard ({gamename,
                store,
                gamedescription,
                gamelink,
                gamecoverurl,
                freeuntil}) {
    let freeDate = freeuntil !== 'expired' ? `free until ${freeuntil}` : ''

    return (
            <div className='game-card'>
                <a href={gamelink} rel='external'>
                    <img src={gamecoverurl} alt='game-poster' loading="lazy" />
                </a>
                <h4> {store} {freeDate}</h4>
                <h2> {gamename} </h2>
            </div>
    )
}

export default GameCard
