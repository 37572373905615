import React from 'react'
import './footer.css'
import freeGames from '../data/freeGames.js'

function Footer () {
    return (
        <div className='Footer'>
            <h2>Tracking your favorite platforms since 2021 with {freeGames.length} free games in the library</h2>
            <p>FreeGameFever.com © Copyright 2023. All Rights Reserved</p>
            <a href="/privacy-policy">Privacy policy</a>
        </div>
    )
}

export default Footer