import React from 'react'
import './faq.css'

function FAQ() {
    return (
        <div className='Faq'>
            <h2>Frequently asked questions</h2>
            <ul className='FaqList'>
                <li className='QuestionPair'>
                    <p className='Question'>Is Epic Games free?</p>
                    <p className='Answer'>Epic Games Store releases a free game each Thursday.</p>
                </li>
                <li className='QuestionPair'>
                    <p className='Question'>Where can I play all games for free? / Where can I download game for free?
</p>
                    <p className='Answer'>We list Triple-A games that used to cost money but are currently free for a limited time.</p>
                </li>
                <li className='QuestionPair'>
                    <p className='Question'>What PC games to play?</p>
                    <p className='Answer'>If you are uncertain of what to play, why not try something that is currently free so you don't spend money on something you might not like!</p>
                </li>
                <li className='QuestionPair'>
                    <p className='Question'>Is Triple-A games free?</p>
                    <p className='Answer'>Generally, you pay around $60 for AAA games. Sometimes, game platforms give out free games for a limited time, you can find those games here!</p>
                </li>
                <li className='QuestionPair'>
                    <p className='Question'>Which platform gives free games?</p>
                    <p className='Answer'>Steam, Epic Games Store, GoG and Robot Cache sometimes gives free games.</p>
                </li>
            </ul>
        </div>
   )
}

export default FAQ