import React from 'react'
import Logo from '../logo.png'
import Icon from '../icon.png'
import './header.css'

function Header() {
    return (
        <a className='link' href="/">
            <header className='App-header'>
                    <div className='Header-left'>
                        <img src={Icon} className='App-icon' alt='free-game-fever-icon' />
                        <img src={Logo} className='App-logo' alt='free-game-fever-logo' />
                    </div>
                    <p className='Platforms'>
                        Steam | Epic Games Store | GoG | Robot Cache
                    </p>
            </header>
        </a>
    )
}

export default Header
