import React from 'react'
import './banner.css'

function Banner() {
    return (
        <div className='Banner'>
            <div className='Banner-chat'>
                <p className='Banner-shoutout'>Welcome to the free game store!</p>
                <p className='Banner-guidance'>Where can I download game for free? We have collected all the free games for you!</p>
                <p className='Banner-slogan'>Hold onto your controllers, because you've just found the ultimate destination for free games in the online gaming arena. Our platform curates top-quality games from the biggest names, and the best part is – they're all free for your disposal!</p>
                <p className='Banner-shoutout'>No wallet, no worries!</p>
            </div>
        </div>
   )
}

export default Banner