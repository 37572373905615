import React from 'react'

function PrivacyPolicy() {
    return (
        <div>
            <h2>Free Game Fever Privacy Policy</h2>
            <h4>Effective Date: 23 July 2023</h4>

            <h3>Introduction</h3>
            <p>Welcome to Free Game Fever ("we," "us," or "our"). We are committed to safeguarding the privacy of our website visitors and users. This Privacy Policy outlines how we collect, use, disclose, and manage your personal information in accordance with the General Data Protection Regulation (GDPR) and other applicable data protection laws.</p>

            <p>By accessing or using Free Game Fever, you consent to the practices described in this Privacy Policy. If you do not agree with this policy, please do not use our website.</p>

            <h3>Information We Collect</h3>
            <h4>2.1 Non-Personal Information</h4>

            <p>We may collect non-personal information automatically when you visit our website. This information may include:</p>

            <ul>
                <li>Browser type and version</li>
                <li>Operating system</li>
                <li>Referral source</li>
                <li>Website usage data (e.g., pages visited, time spent on the site)</li>
            </ul>

            <h4>2.2 Cookies and Similar Technologies</h4>

            <p>We may use cookies and similar technologies to enhance your experience on our website. These technologies help us understand how you interact with our site, analyze trends, and optimize our content.</p>

            <h4>How We Use Your Information</h4>
            <p>We use non-personal information and cookies for the following purposes:</p>

            <ul>
                <li>To improve and optimize our website's performance and user experience</li>
                <li>To analyze website traffic and usage patterns</li>
                <li>To track the effectiveness of our marketing campaigns</li>
                <li>To understand user preferences and interests</li>
            </ul>

            <h4>Third-Party Services</h4>
            <p>We use the following third-party services on our website:</p>

            <ul>
                <li>Google Analytics: We use Google Analytics to gather and analyze website usage data. Google Analytics uses cookies to collect information about your interactions with our site. For more information about Google Analytics' data practices, please review Google's privacy policy.</li>
                <li>Google AdSense: We may use Google AdSense to display advertisements on our website. AdSense uses cookies to show personalized ads based on your interests. To learn more about how Google uses data for advertising, please review Google's privacy policy.</li>
                <li>Firebase: We may use Firebase, a mobile and web application development platform, to improve the performance and functionality of our website. Firebase may collect usage data to provide analytics and other services. For more information, please review Firebase's privacy policy.</li>
            </ul>

            <h4>Data Transfers</h4>
            <p>We may transfer your non-personal information, including cookies and website usage data, to the third-party services mentioned above. When doing so, we will ensure that adequate safeguards are in place to protect your data as required by GDPR.</p>

            <h4>Your Rights</h4>
            <p>As a data subject under the GDPR, you have certain rights concerning your personal data. However, since we only collect non-personal information through cookies and similar technologies, these rights may not be applicable to the data collected. Nevertheless, you have the right to control your cookie preferences through your browser settings.</p>

            <h4>Contact Us</h4>
            <p>If you have any questions, concerns, or requests related to this Privacy Policy or your data, please contact us at:</p>

            webmasterfreegamefever@gmail.com

            <h4>Updates to this Privacy Policy</h4>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will post the updated version on our website, and the effective date will be indicated at the beginning of the policy.</p>

            <p>Please review this Privacy Policy periodically. Your continued use of our website after any modifications indicates your acceptance of the updated Privacy Policy.</p>

            Last Updated: 23 July 2023
        </div>
    )
}

export default PrivacyPolicy
