import React from 'react'

import Hero from '../components/Hero.js'
import Banner from '../components/Banner.js'
import FAQ from '../components/FAQ.js'
import './home.css'
import Row from '../components/Row.js'
import freeGames from '../data/freeGames.js'

function Home() {
    const filterFavorites = (game) => {
            const favorites = [
                       'The Elder Scrolls Online',
                       'Death Stranding',
                       'Dishonored®: Death of the Outsider™',
                       'STAR WARS™: Squadrons',
                       'Hundred Days - Winemaking Simulator',
                       'Mafia',
                       'Shadow of the Tomb Raider: Definitive Edition',
                       'BioShock: The Collection',
                       'Borderlands 3',
                       'Terraforming Mars',
                       'Amnesia: Rebirth'
                       ]

            return favorites.includes(game.gamename)
    }

    return (
    <>
        <Hero />

        <Row games={freeGames.filter(game => game.freeuntil !== 'expired')} rowname='All Free Games Right Now' subtitle='What games can I play free?'/>
        <Banner />
        <Row games={freeGames.filter(game => filterFavorites(game))} rowname='Our favorite previously free games' subtitle="Best Triple-A PC Games You Can Play For Free if you'd followed us earlier"/>
        <Row games={freeGames.filter(game => game.freeuntil === 'expired')} rowname='All games that you missed' subtitle='What games did Epic Games give?' overflowButton />
        <FAQ />
    </>
    )
}

export default Home
