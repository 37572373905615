import React from 'react'
import Pacman from '../pacman.png'
import './hero.css'

function Hero() {
    return (
        <div className='Hero'>
            <img src={Pacman} className='Hero-img' alt='hero shoutout'/>
            <div className='Hero-chat'>
                <p className='Hero-shoutout'>Hey you!</p>
                <p className='Hero-slogan'>Cure your fever, play free games and never look back! We keep track of the best free game deals right now so you don't have to!</p>
            </div>
        </div>
   )
}

export default Hero